<template>
  <div>
    <div class="header dashboard">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12">
            <nav
              class="navbar navbar-expand-lg navbar-light px-0 justify-content-between"
            >
              <a class="navbar-brand" href="/"
                ><img src="@/assets/images/logo.png" alt=""
              /></a>

              <div class="header-right d-flex my-2 align-items-center">
                <div class="language">
                  <div class="dropdown">
                    <div class="icon" data-toggle="dropdown">
                      <i class="flag-icon flag-icon-us"></i>
                      <span>English</span>
                    </div>
                  </div>
                </div>
                <div class="dashboard_log">
                  <div class="profile_log dropdown">
                    <div class="user" @click="showDropdown = !showDropdown">
                      <span class="thumb"><i class="mdi mdi-account"></i></span>
                      <span class="arrow"
                        ><i class="la la-angle-down"></i
                      ></span>
                    </div>

                    <div
                      v-show="showDropdown"
                      class="dropdown-menu dropdown-menu-right"
                    >
    

                      <div class="user-balance">
                        <div class="available"></div>
                        <div class="total">
                          <p align="center">{{ balance }} USD</p>
                          <span>Available Deposit</span>
                        </div>
                      </div>
                      <div @click="showDropdown = false">
                        <router-link to="profile" class="dropdown-item" @click="showDropdown = false">
                          <i class="mdi mdi-account"></i> Account Overview
                        </router-link>
                        <router-link to="transaction-history" class="dropdown-item">
                          <i class="mdi mdi-history"></i> Transaction History
                        </router-link>
                        <a href="javascript:void(0)" class="dropdown-item logout" @click="logout">
                          <i class="mdi mdi-logout"></i> Logout
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <div class="sidebar">
      <div class="menu">
        <ul>
          <li
            v-for="link in links"
            :key="link.icon"
            :class="$route.name === link.title ? 'active' : null"
          >
            <router-link
              :to="{name: link.title}"
              data-toggle="tooltip"
              data-placement="right"
              :title="link.title"
            >
              <span><i :class="link.icon"></i></span>
            </router-link>
          </li>
          <li :class="$route.name === 'logout' ? 'active' : null">
            <a
              href="javascript:void(0)"
              data-toggle="tooltip"
              data-placement="right"
              title="Logout"
              @click="logout"
            >
              <span><i class="mdi mdi-logout"></i></span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <main class="content-body">
      <router-view />
    </main>

    <div class="footer dashboard">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-8 col-12">
            <div class="copyright">
              <p>
                © Copyright 2020 <a href="https://getcoins.com">Getcoins</a> All
                Rights Reserved
              </p>
            </div>
          </div>
          <div class="col-sm-4 col-12">
            <div class="footer-social">
              <ul>
                <li>
                  <a href="#"><i class="fa fa-facebook"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fa fa-twitter"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fa fa-linkedin"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fa fa-youtube"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api';
import client from '@/api/client';
import user from '@/api/user';

export default {
  data: () => ({
    showDropdown: false,
    balance: 0,
    userData: null,
    links: [
      {
        to: 'dashboard',
        title: 'Dashboard',
        icon: 'mdi mdi-view-dashboard',
      },
      {
        to: 'trade',
        title: 'Trade',
        icon: 'mdi mdi-twitter-retweet',
      },
      {
        to: 'transaction-history',
        title: 'History',
        icon: 'mdi mdi-face-profile',
      },
      {
        to: 'profile',
        title: 'Profile',
        icon: 'mdi mdi-cog',
      },
    ],
  }),
  computed: {
    name() {
      const data = this.userData;
      if (data) {
        return `${data.first_name} ${data.last_name}`;
      }
      return null;
    },
  },
  mounted() {
    this.getBalance();
    this.getUser();
  },
  methods: {
    getUser() {
      user.account().then((res) => {
        this.userData = res;
      })
    },
    logout() {
      localStorage.removeItem('refreshToken');
      window.sessionStorage.clear();
      window.location.reload();
    },
    getBalance() {
      api.balance().then((res) => {
        this.balance = res || 0;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content-body {
  margin-left: 75px;
  margin-top: 70px;
  margin-right: 6px;
  margin-bottom: 45px;
  min-height: calc(100vh - 115px);
}

li.active {
  a {
    background-color: var(--primary);
  }
}
</style>
